<div class="dropdown-section sm:pb-0 pb-3 w-[366px]" [class]="dropdownAlignment">
    <p class="dropdown-label font-extrabold">{{dropdownTopLabel}}</p>
    <div class="dropdown inline-block relative text-center hidden sm:block">
        <button
        class="dropdown-button relative leading-6 flex justify-between"
        (keyup.enter)="dropdownCollapse = !dropdownCollapse"
        (click)="toggleDropdown()"
        >
            <span class="mr-2 dropdown-text truncate
            ">{{ dropdownPlaceholder }}</span>
            <div class="dropdown-items">
                <span class="dropdown-item-line"></span>
                <fa-icon [icon]="faCaretDown" class="fa-xs dropdown-icon dropdown-caret"> </fa-icon>
                <fa-icon [icon]="faChevronDown" class="fa-xs dropdown-chevron"> </fa-icon>
            </div>
        </button>
        <div class="h-2"></div>
        <ul
        class="dropdown-menu dropdown-section absolute text-left overflow-y-auto bg-white rounded-md  w-[366px] shadow-sm drop-shadow-sm z-50 w-full"
        [ngClass]="{ hidden: !dropdownCollapse, block: dropdownCollapse }"
        >
            <ng-template ngFor let-d [ngForOf]="dropdownItems">
                <!-- If no link, set item as a section header -->
                <li *ngIf="!d.dropdownLink && !d.dropdownValue" class="dropdown-item-header block pt-4 px-6 pb-1">
                  <label *ngIf="!d.dropdownLink" class="eyebrow">
                    {{ d.dropdownLabel }}
                  </label>
                </li>
                <!-- If dropdown value is present render a button instead of a link -->
                <li *ngIf="d.dropdownValue">
                  <!-- handleChange acts as a function to simulate the onChange ev from a select element -->
                  <button (click)="handleChange(d.dropdownValue)" class="dropdown-item text-left py-3 px-6 w-full">
                    {{ d.dropdownLabel }}
                  </button>
                </li>
                <!-- If link, set item as a clickable link -->
                <li *ngIf="d.dropdownLink" class="dropdown-item">
                <a
                    class="block py-3 px-6"
                    href="{{ d.dropdownLink }}"
                    [attr.aria-label]="getAriaLabelLink(d.dropdownLink, d.dropdownLabel)"
                    [attr.target]="d.dropdownLinkNewTab ? '_blank' : ''"
                    [attr.data-formelementid]="d.dropdownCtaTag ? d.dropdownCtaTag : null"
                    [attr.rel]="
                    d.dropdownLinkNewTab && d.dropdownLinkNofollow
                        ? 'nofollow noopener noreferrer'
                        : d.dropdownLinkNewTab && !d.dropdownLinkNofollow
                        ? 'noopener noreferrer'
                        : d.dropdownLinkNofollow
                        ? 'nofollow'
                        : ''
                    "
                >
                    {{ d.dropdownLabel }}
                </a>
                    <label *ngIf="!d.dropdownLink" class="text-vis-regular">
                        {{ d.dropdownLabel }}
                    </label>
                </li>

            </ng-template>
        </ul>
    </div>
      <select [attr.aria-label]="dropdownTopLabel ?? 'Select an option'"
        (change)="!dropdownItems[0].dropdownValue ? onChange($event) : handleChange($event)" id="mobile_select"
        class="sm:hidden xs:inline-flex relative truncate">
        <option selected disabled [value]="dropdownPlaceholder">{{ dropdownPlaceholder }}</option>
        <option *ngFor="let d of dropdownItems" value="{{ !d.dropdownValue ? d.dropdownLink : d.dropdownValue }}"
          [class.new-tab]="d.dropdownLinkNewTab" [disabled]="!d.dropdownLink && !d.dropdownValue">
          {{ d.dropdownLabel }}
        </option>
      </select>
</div>
