import type { HeaderNavLink } from './header';

export const headerData: HeaderNavLink[] = [
  {
    linkUrl: '#',
    linkLabel: 'Pre-License',
    isDropDown: true,
    menuSize: '340px',
    ddList: [
      {
        linkUrl: '/pre-license/illinois/',
        linkLabel: '<strong>Illinois</strong> Insurance License',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: '/continuing-education/submit-exam/',
    linkLabel: 'Submit CE Exam',
    menuSize: '340px',
    isDropDown: false,
  },
  {
    linkUrl: '#',
    linkLabel: 'Continuing Ed',
    menuSize: '340px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: '/continuing-education/california/',
        linkLabel: '<strong>California</strong> Continuing Education',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/continuing-education/illinois/',
        linkLabel: '<strong>Illinois</strong> Continuing Education',
        newTab: false,
        nofollow: false,
      },
    ],
  },
  {
    linkUrl: '/about-us/',
    linkLabel: 'About Us',
    menuSize: '340px',
    isDropDown: false,
  },
  {
    linkUrl: '/resources/',
    linkLabel: 'Resources',
    menuSize: '340px',
    isDropDown: false,
  },
];

export const myAccountData: HeaderNavLink[] = [
  {
    linkUrl: '#',
    linkLabel: 'My Account',
    menuSize: '180px',
    isDropDown: true,
    ddList: [
      {
        linkUrl: 'https://app.aceable.com/',
        linkLabel: 'Sign In',
        newTab: false,
        nofollow: false,
      },
      {
        linkUrl: '/support/',
        linkLabel: 'Help Center',
        newTab: false,
        nofollow: false,
      },
    ],
  },
];
