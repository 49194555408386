<!-- Nav Link -->
<span
  class="header__link relative text-whitehover:text-ace-teal-800 flex align-middle px-4 cursor-pointer whitespace-nowrap"
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  *ngIf="data.isDropDown"
  [href]="data.linkUrl"
  (keydown.enter)="enterPress()"
  tabindex="0"
>
  {{ data.linkLabel }}
  <fa-icon class="ml-3 fa-1x" [icon]="hovered ? faCaretUp : faCaretDown"></fa-icon>
  <!-- MouseOver Bridge to DropDown -->
  <div
    class="absolute h-12 top-4 w-full -ml-8"
    (mouseenter)="hovered = true"
    [class.hidden]="!data.isDropDown || !hovered"
  ></div>
  <!-- Drop down Caret -->
  <div
    class="absolute ml-auto mr-auto z-500 triangle-pointer-up left-1/4"
    [class.hidden]="!data.isDropDown || !hovered"
  ></div>
</span>
<a
  class="header__link relative text-vis-reverse flex align-middle px-4 xl:px-8 whitespace-nowrap"
  [href]="data.linkUrl"
  target="{{ data.openNewTab ? '_blank' : '_self' }}"
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  *ngIf="!data.isDropDown"
  [attr.aria-label]="getAriaLabelLink(data.linkUrl, data.linkLabel)"
>
  {{ data.linkLabel }}
</a>
<!-- Simple DropDown -->
<div
  (mouseleave)="hovered = false"
  (mouseenter)="hovered = true"
  class="header__dd-simple w-1/4 z-30 absolute top-18 py-v-spacing sm:px-v-spacing bg-white rounded-md"
  [class.hidden]="(!data.items || !hovered) && !showMenu"
  (blur)="onBlur($event)"
>
  <div *ngFor="let link of data.items; let last = last; let first = first">
    <a
      [class]="'text-base not-italic font-normal leading-6 px-0 py-1 flex flex-row w-full text-sm font-medium hover:text-ace-teal-800 ' +  (last ? 'last-simple-item ' : first ? 'first-simple-item ' : '') + (link.arrowLink ? 'text-ace-teal-800' : 'text-dark')"
      [href]="link.linkUrl"
      (blur)="onBlur($event)"
      (keydown)="onKeyDown($event)"
      tabindex="0"
      [attr.aria-label]="getAriaLabelLink(link.linkUrl, link.linkLabel)"
    >
      <span [innerHTML]="link.linkLabel"></span>
      <span [class]="(!link.arrowLink ? 'hidden' : 'pt-1 pl-1')" aria-label="arrow all state" aria-hidden="true" >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.45007 12.9333C7.29451 12.7889 7.21673 12.6083 7.21673 12.3916C7.21673 12.175 7.29451 11.9889 7.45007 11.8333L10.5001 8.78332H3.26673C3.04451 8.78332 2.8584 8.70832 2.7084 8.55832C2.5584 8.40832 2.4834 8.22221 2.4834 7.99998C2.4834 7.77776 2.5584 7.59165 2.7084 7.44165C2.8584 7.29165 3.04451 7.21665 3.26673 7.21665H10.5001L7.45007 4.14998C7.29451 4.00554 7.21673 3.82221 7.21673 3.59998C7.21673 3.37776 7.29451 3.18887 7.45007 3.03332C7.60562 2.88887 7.79173 2.81665 8.0084 2.81665C8.22507 2.81665 8.41118 2.88887 8.56673 3.03332L12.9834 7.44998C13.0612 7.52776 13.1195 7.61387 13.1584 7.70832C13.1973 7.80276 13.2167 7.89998 13.2167 7.99998C13.2167 8.09998 13.1973 8.19721 13.1584 8.29165C13.1195 8.38609 13.0612 8.47221 12.9834 8.54998L8.56673 12.9666C8.41118 13.1222 8.22507 13.1944 8.0084 13.1833C7.79173 13.1722 7.60562 13.0889 7.45007 12.9333Z" fill="#169AA9"/>
        </svg>        
      </span>  
    </a>
  </div>
</div>
