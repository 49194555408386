import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { TrustpilotService } from '@core-mkt/services/trustpilot/trustpilot.service';
import { RequestService } from '@core-mkt/services/url/url.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TrustpilotInfoText } from '../../top-section/top-section/trustpilot/trustpilot-data';
import type { ButtonHomePage, ProductPicker, StyleObj } from './hero-homepage';
import { HomepageHero } from './hero-homepage';

@Component({
  selector: 'hero-homepage',
  templateUrl: './hero-homepage.component.html',
  styleUrls: ['./hero-homepage.component.scss'],
})
export class HeroHomepageComponent implements OnInit, AfterViewInit {
  @Input() data: HomepageHero;
  @ViewChild('heroImg') heroImg: ElementRef;
  theme: ThemeInterface;
  faTimes = faTimes;
  imgHeight = 0;
  hasContent = true;
  faChevronRight = faChevronRight;
  trustpilotTrustScore: number;
  trustpilotNumberOfReviews: number;
  trustpilotInfoText = TrustpilotInfoText;
  trustpilotVerified = false;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private tps: TrustpilotService,
    private env: EnvService,
    private requestService: RequestService
  ) {}

  get contentWidth(): string {
    if (this.data.heroImage.length > 0) {
      return 'w-full md:w-1/2';
    }
    return 'w-full';
  }

  get imgWidth(): string {
    if (this.data.heroImage.length > 0) {
      return 'w-full md:w-1/2';
    }
    return 'w-full';
  }

  get heroStyle(): StyleObj {
    const styleObj: StyleObj = {};
    if (this.imgHeight !== 0) {
      styleObj['min-height.px'] = this.imgHeight;
    }
    if (this.data.backgroundImage.length > 0 && this.data.backgroundImage[0].url !== null) {
      styleObj['background-image'] = 'url(' + this.data.backgroundImage[0].url + ')';
    }
    return styleObj;
  }

  get buttons(): ButtonHomePage[] {
    return this.data.buttonList ? this.data.buttonList : this.data.aarpButtonList;
  }

  get isImageAlignmentToLeft(): boolean {
    return this.data.imageAlignment === 'flex-row-reverse';
  }

  get showImageOnMobile(): boolean {
    return this.data.showImageOnMobile;
  }

  get imageBleedClass(): string {
    if (!this.isImageAlignmentToLeft) {
      return 'left-img-bleed';
    }
    return '';
  }

  /**
   * Gets the content of the product picker.
   * @returns The first element of the productPicker array from Craft
   */
  get productPickerContent(): ProductPicker[] {
    if (
      this.data.productPickerTable &&
      this.data.productPickerTable.length > 0 &&
      this.data.productPickerTable[0].productPickerTable
    ) {
      return this.data.productPickerTable;
    }
  }

  get heroImageUrl(): string {
    if (this.data.heroImage[0].url.includes('auto=compress,format')) {
      return this.data.heroImage[0].url;
    }
    return `${this.data.heroImage[0].url}&auto=compress,format`;
  }

  get TrustScoreImageUrl(): string {
    if (this.data.TrustScoreImage && this.data.TrustScoreImage[0].url) {
      if (this.data.TrustScoreImage[0].url.includes('auto=compress,format')) {
        return this.data.TrustScoreImage[0].url;
      }
      return `${this.data.TrustScoreImage[0].url}&auto=compress,format`;
    }
    return 'https://xgrit-ecom.imgix.net/ace/trustpilot-star-rating.svg?auto=compress,format&v=1706889351';
  }

  get TrustScoreImageAltText(): string {
    if (this.data.TrustScoreImage && this.data.TrustScoreImage[0].altText) {
      return this.data.TrustScoreImage[0].altText;
    }
    return 'TrustScore 5 out of 5 Stars';
  }
  
  ngOnInit(): void {
    if (!this.data) {
      this.hasContent = false;
      return;
    }
    if (this.data.heroContent === null) {
      this.data.heroContent = '';
    } else {
      this.data.heroContent = this.redactor.bypassSanitizer(this.data.heroContent, 'lazy');
    }

    if (this.data.heroTitle === null) {
      this.data.heroTitle = '';
    } else {
      this.data.heroTitle = this.redactor.bypassSanitizer(this.data.heroTitle);
    }
    this.hasContent = !(this.data.heroContent.length === 0 && this.data.heroImage.length === 0);
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: this.data.imageAlignment,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);

    if (this.data.showTrustpilot && this.env.get.brandConfig.trustpilotId.length > 0) {
      this.tps.getBrandStarsRating().then((trustPilotRating) => {
        this.trustpilotTrustScore = trustPilotRating.score.trustScore;
        this.trustpilotNumberOfReviews = trustPilotRating.numberOfReviews.total;
        this.trustpilotVerified = this.trustpilotNumberOfReviews > 0;
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.heroImg) {
      //a minute delay must be granted to allow image to render
      setTimeout(() => {
        this.imgHeight = this.showImageOnMobile ? this.heroImg.nativeElement.offsetHeight : 0;
      }, 25);
    }
  }

  getAriaLabelLink(link: string, html: string): string {
    return this.requestService.getAriaLabel(link, html);
  }
}
