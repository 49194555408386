import type { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { Component, Inject, Input, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import type { CraftXGritProductEntry } from '@core-mkt/interfaces/craft-xgrit-product-entry';
import { EnvService } from '@core-mkt/services/env/env.service';
import { IconService } from '@core-mkt/services/iconService/icon.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import type { BundleAccordionItem, BundleItem } from './bundle';
import { Bundle } from './bundle';

@Component({
  selector: 'bundle',
  templateUrl: './bundle.component.html',
  styleUrls: ['./bundle.component.scss'],
})
export class BundleComponent implements OnInit, AfterViewInit {
  @Input() data: Bundle;
  @ViewChildren('bundlePrice') bundlePrice: QueryList<ElementRef>;
  theme: ThemeInterface;
  faPlus = faPlus;
  faMinus = faMinus;
  faTimes = faTimes;
  showModal = false;
  couponApplied = false;
  activeCoupon = '';
  additionalCoupon: string; // Used to store additional coupon code from the cookie
  closeIcon: SafeHtml | null;
  openIcon: SafeHtml | null;
  topContent: SafeHtml | null;
  urlCouponPresent: boolean[] = [];
  guaranteeTextOptions = {
    moneyBack: {
      text: 'Pass the test or your money back!',
      modalTitle: `Ace or Don't Pay Guarantee`,
      modalBody: `Our online real estate license course is so effective that we guarantee if you don't pass the licensing exam on your first try, we'll refund your money. Additional terms and conditions apply. State laws and regulations may prohibit some students from qualifying. Review our refund policy to learn more.`,
    },
    satisfactionGuarantee: {
      text: '30-Day Satisfaction Guarantee',
      modalTitle: `30-Day Satisfaction Guarantee!`,
      modalBody: `Try AceableAgent for 30 days. If you're not satisfied and haven’t completed your course, we’ll refund your money. Review our refund policy to learn more.`,
    },
  };

  constructor(
    private themeParser: ThemeParserService,
    private redactor: WysiwygRedactorService,
    private ps: ProductService,
    private pricingService: PricingService,
    private IconService: IconService,
    private env: EnvService,
    @Inject(PLATFORM_ID) protected platformId: any,
  ) {}

  get bundles(): BundleItem[] {
    return this.data.bundles;
  }

  get accordions(): BundleAccordionItem[][] {
    return [this.data.bundleAccordionItems1, this.data.bundleAccordionItems2, this.data.bundleAccordionItems3];
  }

  get guaranteeText(): string {
    return this.guaranteeTextOptions[this.data.guaranteeText as keyof typeof this.guaranteeTextOptions].text;
  }

  get guaranteeModalTitle(): string {
    return this.guaranteeTextOptions[this.data.guaranteeText as keyof typeof this.guaranteeTextOptions].modalTitle;
  }

  get guaranteeModalBody(): string {
    return this.guaranteeTextOptions[this.data.guaranteeText as keyof typeof this.guaranteeTextOptions].modalBody;
  }

  ngOnInit(): void {
    this.data.guaranteeText = this.data.guaranteeText || 'moneyBack';
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: '',
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    this.topContent = this.redactor.bypassSanitizer(this.data.topContent);
    this.openIcon = this.redactor.bypassSanitizer(this.IconService.getBrandIcon('accordion-open')?.icon);
    this.closeIcon = this.redactor.bypassSanitizer(this.IconService.getBrandIcon('accordion-close')?.icon);

    this.data.bundles.forEach((b) => {
      b.principalContent = this.redactor.bypassSanitizer(b.principalContent);
      b.bottomContent = this.redactor.bypassSanitizer(b.bottomContent);
    });

    this.getProducts(this.data.bundles);
    this.processAccordionItems(this.data.bundleAccordionItems1);
    this.processAccordionItems(this.data.bundleAccordionItems2);
    this.processAccordionItems(this.data.bundleAccordionItems3);

    this.pricingService.couponCodeEvent.subscribe((coupon: string) => {
      this.activeCoupon = '&couponCode=' + coupon;
      this.additionalCoupon = coupon;
      this.adjustPricing(coupon);
    });
  }

  ngAfterViewInit(): void {
    /**
     * Detect when ViewChildren bundlePrice changes and
     * adjust pricing if coupon is already applied
     */

    this.bundlePrice.changes.subscribe(() => {
      // 2nd condition prevent emailCapture coupon to override ongoing sale
      if (this.activeCoupon && this.urlCouponPresent.every((isPresent) => !isPresent)) {
        // If activeCoupon is already set, adjust pricing
        this.adjustPricing(this.additionalCoupon);
      }
    });
  }

  private processAccordionItems(items: BundleAccordionItem[]): void {
    items.forEach((i: BundleAccordionItem) => {
      i.itemDescription = this.redactor.bypassSanitizer(i.itemDescription);
    });
  }

  public adjustPricing(coupon: string): void {
    this.bundlePrice.forEach((el, index) => {
      const bundle = this.data.bundles[index];
      const product = this.ps.extractProduct(bundle.product);
      product.xgritCoupon = coupon;
      this.ps.getProductData(product).then((product: XGritCompleteProduct) => {
        const start = product.apiData.originalPrice;
        const end = product.apiData.discountPrice;
        const maxPrice = product.apiData.maxPrice;
        const discountPrice = product.apiData.discountPrice;
        bundle.savePercentage = Math.floor(((maxPrice - discountPrice) / maxPrice) * 100);
        bundle.maxPrice = maxPrice;
        bundle.originalPrice = discountPrice;
        bundle.discountType = product.apiData.discountType;

        this.couponApplied = true;
        if (start === end) return;
        let current = start;
        const stepTime = 14;
        const timer = setInterval(function () {
          current -= 1;
          el.nativeElement.innerHTML = '$' + current;
          if (current <= end) {
            clearInterval(timer);
            return;
          }
        }, stepTime);
      });
    });
  }

  toggle(accordionItem: BundleAccordionItem): void {
    accordionItem.showDescription = !accordionItem.showDescription;
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
  }

  showAccordion(index: number): boolean {
    return !!this.accordions[index]?.[0]?.itemTitle;
  }

  getThemeForTopBanner(backgroundColor: string, textColor: string): ThemeInterface {
    if (backgroundColor === '') {
      const theme: ThemeInterface = {
        root: '',
        container: '',
        columnOrder: '',
        bgImage: '',
      };
      return theme;
    } else {
      const themeInput: ComponentTheme = {
        styles: [],
        backgroundColor: backgroundColor,
        backgroundImage: null,
        textColor: textColor,
        columnCount: 0,
        columnDistribution: '',
      };
      return this.themeParser.getThemeClasses(themeInput);
    }
  }

  getButtonData(bundleItem: BundleItem): VisionButton {
    const buttonLink = bundleItem.ctaLink ? bundleItem.ctaLink : bundleItem.productCheckoutUrl ?? '';
    // Do not append another couponCode into the checkout url
    const urlCouponPresent = buttonLink ? buttonLink.includes('couponCode') : false;
    return {
      bgTheme: this.theme.root,
      buttonLink: `${buttonLink}${urlCouponPresent ? '' : this.activeCoupon}`,
      buttonText: bundleItem.ctaLabel || this.env.get.brandConfig.ctaText,
      buttonAriaLabel: bundleItem.ctaLinkAriaLabel ? bundleItem.ctaLinkAriaLabel : undefined,
      buttonStyle: bundleItem.ctaStyle,
      newTab: bundleItem.ctaLinkNewTab,
      nofollow: bundleItem.ctaLinkNofollow,
      noopener: bundleItem.ctaLinkNewTab,
      noreferrer: bundleItem.ctaLinkNewTab,
      fullWidth: false,
    };
  }

  getProducts(bundles: BundleItem[]): void {
    const craftProducts: CraftXGritProductEntry[] = [];
    bundles.forEach((bundle) => {
      if (this.ps.isProductValid(bundle.product)) {
        craftProducts.push(this.ps.extractProduct(bundle.product));
      }
    });
    this.ps.getMultiProductData(craftProducts).then((xgritProducts) => {
      if (xgritProducts.length !== 0) {
        bundles.forEach((bundle, index) => {
          const maxPrice = xgritProducts[index].apiData.maxPrice;
          const originalPrice = xgritProducts[index].apiData.originalPrice;
          const discountPrice = xgritProducts[index].apiData.discountPrice;
          bundle.productCheckoutUrl = xgritProducts[index].apiData.checkoutLink;
          bundle.discountType = xgritProducts[index].apiData.discountType;
          bundle.ctaLinkAriaLabel = xgritProducts[index].apiData.title;

          // Recording every time a product has a couponCode implying there's an ongoing sale
          this.urlCouponPresent.push(
            xgritProducts[index].apiData.checkoutLink && xgritProducts[index].craftData.xgritCoupon
              ? xgritProducts[index].apiData.checkoutLink.includes('couponCode') &&
                  xgritProducts[index].apiData.checkoutLink.includes(xgritProducts[index].craftData.xgritCoupon)
              : false,
          );
          if (
            xgritProducts[index].apiData.maxPrice &&
            xgritProducts[index].apiData.maxPrice !== xgritProducts[index].apiData.originalPrice
          ) {
            // Show Save % and both prices
            bundle.savePercentage = Math.floor(((maxPrice - originalPrice) / maxPrice) * 100);
            bundle.maxPrice = maxPrice;
            bundle.originalPrice = originalPrice;
          } else if (
            xgritProducts[index].apiData.maxPrice &&
            xgritProducts[index].apiData.discountPrice &&
            xgritProducts[index].apiData.maxPrice === xgritProducts[index].apiData.originalPrice
          ) {
            // Show Save % and both prices
            bundle.savePercentage = Math.floor(((maxPrice - discountPrice) / maxPrice) * 100);
            bundle.maxPrice = maxPrice;
            bundle.originalPrice = discountPrice;
          } else {
            // Only final price
            bundle.originalPrice = originalPrice;
          }

          // Use discountPrice to calculate monthly payments, as it is the final price after all discounts
          bundle.affirmPrice = Math.ceil(discountPrice * (1.085 / 12));
          bundle.klarnaPrice = +(discountPrice / 4).toFixed(2);
        });
      }
    });
  }
}
