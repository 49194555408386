import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  getFullUrl(): string {
    return '';
  }

  getSeoUrl(): string {
    return '';
  }

  getProtocol(): string {
    return '';
  }

  getHost(): string {
    return '';
  }

  getHostname(): string {
    return '';
  }

  getPort(): string {
    return '';
  }

  getPathname(): string {
    return '';
  }

  getQueryParams(): { [key: string]: string } {
    return {};
  }

  getQueryParam(key: string): string | null {
    return null;
  }

  getHash(): string {
    return '';
  }

  /**
   * Gets the aria-label value to the anchor element
   * Removing dashes and slashes from a link
   * Validating type of url link
   * @param link The string with the URL
   * @param html The string with html code
   */
  getAriaLabel(link: string, html?: string): string {
    if (link === '/') {
      return 'Home';
    }
    if (link?.startsWith('http')) {
      return this.removeHTMLTags(html);
    }

    const sectionList = link?.split('/');
    if (sectionList[0] === '') {
      sectionList.shift();
    }

    sectionList.pop();
    sectionList.push(sectionList[0]);
    sectionList.shift();

    const firstReplae = sectionList.toString().replace(/\-/gi, ' ');
    const ariaLabel = firstReplae.replace(/\,/gi, ' ');
    return ariaLabel.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());
  }

  /**
   * Remove html code from a string
   * @param html The string with html code
   */
  removeHTMLTags(html: string): string {
    if (html === null || html === '') {
      return '';
    }
    html = html?.toString();
    return html?.replace(/(<([^>]+)>)/gi, '');
  }
}
