<div [className]="theme?.root + ' bg-no-repeat bg-center bg-cover'" [style]="theme?.bgImage">
  <div class="container {{ theme.container }}">
    <div
      *ngIf="topContent"
      class="wysiwyg py-v-spacing tab:px-v-spacing max-w-vision mx-auto bundle__top-content"
      [innerHtml]="topContent"
    ></div>
    <div class="mx-auto" [ngClass]="{ 'w-fit': bundles.length > 1 }">
      <!-- Mobile View -->
      <div *ngIf="bundles.length > 1" class="gap-x-6 grid grid-cols-1 justify-items-center lg:grid-cols-3 lg:hidden mobile-grid">
        <ng-template ngFor let-b [ngForOf]="bundles" let-last="last" let-index="index">
          <div
            class="pt-v-spacing w-full flex flex-col max-w-[366px]"
            [ngClass]="{ 'basic-column': index === 0, 'deluxe-column': index === 1, 'premium-column': index === 2 }"
          >
            <div
              [className]="getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root + ' rounded-t-md w-full'"
              [ngClass]="{ 'h-9': !!b.topContent, 'h-0 lg:h-9': !b.topContent }"
            >
              <span
                [className]="
                  getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root +
                  ' text-sm font-extrabold rounded-t-md inline-block uppercase py-2 px-6 w-full'
                "
                >{{ b.topContent }}</span
              >
            </div>
            <div
              class="w-full rounded-b shadow-xl bg-white text-vis-regular"
              [ngClass]="{ 'rounded-t-md': !b.topContent }"
            >
              <div class="wysiwyg p-v-spacing" [innerHTML]="b.principalContent"></div>
              <div class="w-full pt-0 p-v-spacing">
                <div [class]="b.savePercentage ? '' : 'invisible'" class="text-xs font-bold text-ace-pink-600">
                  SAVE <span *ngIf="b.discountType === 'PERCENT'">{{ b.savePercentage }}%</span><span *ngIf="b.discountType === 'FLAT'">${{ b.maxPrice - b.originalPrice }}</span>
                </div>
                <div>
                  <span
                    *ngIf="b.maxPrice && b.savePercentage"
                    class="text-lg font-extrabold price-cross-color line-through"
                    >${{ b.maxPrice | customNumber : '1.0-0' }}</span
                  >
                  <span *ngIf="b.originalPrice" class="text-lg font-extrabold ml-1 text-ace-teal-900"
                    >${{ b.originalPrice | customNumber : '1.0-0' }}</span
                  >
                </div>
              </div>
              <div *ngIf="b.originalPrice >= 50" class="pb-4 text-xs text-ace-grey-800 px-v-spacing">
                <span
                  >As low as ${{ b.affirmPrice }}/mo with
                  <img class="inline-block affirm-img" src="https://xgrit-ecom.imgix.net/ace/affirm-logo.svg" />
                  †, or<br />
                  4 interest-free payments of ${{ b.klarnaPrice }} with
                  <img class="inline-block klarna-img" src="https://xgrit-ecom.imgix.net/ace/klarna-logo.svg" />
                  ††
                </span>
              </div>
              <div class="w-full pt-0 p-v-spacing items-center">
                <div class="w-full xs:mr-v-spacing lg:mr-0 xl:mr-v-spacing">
                  <vision-button [data]="getButtonData(b)" [className]="'basic-cta_mobile'"></vision-button>
                </div>
                <div class="w-full mt-2 text-center text-sm cursor-pointer text-vis-subtle" (click)="toggleModal()">
                  <span class="inline-block">{{guaranteeText}}</span>
                </div>
              </div>
              <div class="p-v-spacing w-full mt-4" *ngIf="b.bottomContent" [innerHTML]="b.bottomContent"></div>
              <div *ngIf="showAccordion(index)">
                <div *ngFor="let accordionItem of accordions[index]" class="border-ace-gray-300 border-t rounded-b-md">
                  <div class="wrapper">
                    <div class="w-full border-b border-ace-gray-300">
                      <div
                        class="group p-v-spacing item-title flex justify-between items-center cursor-pointer"
                        (click)="toggle(accordionItem)"
                      >
                        <div class="title w-full flex flex-row items-center">
                          <img
                            *ngIf="accordionItem.itemIcon && accordionItem.itemIcon.length > 0"
                            class="rounded-[6px] mr-3"
                            [src]="accordionItem.itemIcon[0].url"
                            [alt]="accordionItem.itemIcon[0].alt"
                          />
                          <div class="inline-block align-top">
                            <p class="font-extrabold">{{ accordionItem.itemTitle }}</p>
                            <p class="block mt-0 font-normal" *ngIf="accordionItem.itemValueDescriptor">
                              {{ accordionItem.itemValueDescriptor }}
                            </p>
                          </div>
                          <span
                            *ngIf="accordionItem.itemBadge"
                            class="badge text-center font-extrabold rounded-[100px] bg-ace-teal-200 text-ace-teal-700 leading-[.8] py-2 px-3 ml-2.5 mr-3 text-sm border-ace-teal-700 border border-solid"
                            >{{ accordionItem.itemBadge }}</span
                          >
                        </div>
                        <div class="flex items-center bundle-fa">
                          <div class="icon text-ace-blue-600 group-hover:text-ace-blue-500 text-base text-lg mt-1">
                            <div *ngIf="!accordionItem.showDescription">
                              <span [innerHTML]="openIcon"></span>
                            </div>
                            <div *ngIf="accordionItem.showDescription">
                              <span [innerHtml]="closeIcon"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="accordionItem.showDescription"
                        class="p-v-spacing item-description w-full wysiwyg border-t border-ace-gray-300"
                        [innerHTML]="accordionItem.itemDescription"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- End Mobile View -->
      <!-- Single Bundle Mobile View -->
      <div *ngIf="bundles.length === 1" class="gap-x-6 grid grid-cols-1 justify-items-center md:grid-cols-3 md:hidden mobile-grid">
        <ng-template ngFor let-b [ngForOf]="bundles" let-last="last" let-index="index">
          <div
            class="pt-v-spacing w-full flex flex-col max-w-[366px]"
            [ngClass]="{ 'basic-column': index === 0, 'deluxe-column': index === 1, 'premium-column': index === 2 }"
          >
            <div
              [className]="getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root + ' rounded-t-md w-full'"
              [ngClass]="{ 'h-9': !!b.topContent, 'h-0 lg:h-9': !b.topContent }"
            >
              <span
                [className]="
                  getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root +
                  ' text-sm font-extrabold rounded-t-md inline-block uppercase py-2 px-6 w-full'
                "
                >{{ b.topContent }}</span
              >
            </div>
            <div
              class="w-full rounded-b shadow-xl bg-white text-vis-regular"
              [ngClass]="{ 'rounded-t-md': !b.topContent }"
            >
              <div class="wysiwyg p-v-spacing" [innerHTML]="b.principalContent"></div>
              <div class="w-full pt-0 p-v-spacing">
                <div [class]="b.savePercentage ? '' : 'invisible'" class="text-xs font-bold text-ace-pink-600">
                  SAVE <span *ngIf="b.discountType === 'PERCENT'">{{ b.savePercentage }}%</span><span *ngIf="b.discountType === 'FLAT'">${{ b.maxPrice - b.originalPrice }}</span>
                </div>
                <div>
                  <span
                    *ngIf="b.maxPrice && b.savePercentage"
                    class="text-lg font-extrabold price-cross-color line-through"
                    >${{ b.maxPrice | customNumber : '1.0-0' }}</span
                  >
                  <span *ngIf="b.originalPrice" class="text-lg font-extrabold ml-1 text-ace-teal-900"
                    >${{ b.originalPrice | customNumber : '1.0-0' }}</span
                  >
                </div>
              </div>
              <div *ngIf="b.originalPrice >= 50" class="pb-4 text-xs text-ace-grey-800 px-v-spacing">
                <span
                  >As low as ${{ b.affirmPrice }}/mo with
                  <img class="inline-block affirm-img" src="https://xgrit-ecom.imgix.net/ace/affirm-logo.svg" />
                  †, or<br />
                  4 interest-free payments of ${{ b.klarnaPrice }} with
                  <img class="inline-block klarna-img" src="https://xgrit-ecom.imgix.net/ace/klarna-logo.svg" />
                  ††
                </span>
              </div>
              <div class="w-full pt-0 p-v-spacing items-center">
                <div class="w-full xs:mr-v-spacing lg:mr-0 xl:mr-v-spacing">
                  <vision-button [data]="getButtonData(b)" [className]="'basic-cta_mobile'"></vision-button>
                </div>
                <div class="w-full mt-2 text-center text-sm cursor-pointer text-vis-subtle" (click)="toggleModal()">
                  <span class="inline-block">Pass the test or your money back!</span>
                </div>
              </div>
              <div class="p-v-spacing w-full mt-4" *ngIf="b.bottomContent" [innerHTML]="b.bottomContent"></div>
              <div *ngIf="showAccordion(index)">
                <div *ngFor="let accordionItem of accordions[index]" class="border-ace-gray-300 border-t rounded-b-md">
                  <div class="wrapper">
                    <div class="w-full border-ace-gray-300">
                      <div
                        class="group p-v-spacing item-title flex justify-between items-center cursor-pointer"
                        (click)="toggle(accordionItem)"
                      >
                        <div class="title w-full flex flex-row items-center">
                          <img
                            *ngIf="accordionItem.itemIcon && accordionItem.itemIcon.length > 0"
                            class="rounded-[6px] mr-3"
                            [src]="accordionItem.itemIcon[0].url"
                            [alt]="accordionItem.itemIcon[0].alt"
                          />
                          <div class="inline-block align-top">
                            <p class="font-extrabold">{{ accordionItem.itemTitle }}</p>
                            <p class="block mt-0 font-normal" *ngIf="accordionItem.itemValueDescriptor">
                              {{ accordionItem.itemValueDescriptor }}
                            </p>
                          </div>
                          <span
                            *ngIf="accordionItem.itemBadge"
                            class="badge text-center font-extrabold rounded-[100px] bg-ace-teal-200 text-ace-teal-700 leading-[.8] py-2 px-3 ml-2.5 mr-3 text-sm border-ace-teal-700 border border-solid"
                            >{{ accordionItem.itemBadge }}</span
                          >
                        </div>
                        <div class="flex items-center bundle-fa">
                          <div class="icon text-ace-blue-600 group-hover:text-ace-blue-500 text-base text-lg mt-1">
                            <div *ngIf="!accordionItem.showDescription">
                              <span [innerHTML]="openIcon"></span>
                            </div>
                            <div *ngIf="accordionItem.showDescription">
                              <span [innerHtml]="closeIcon"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="accordionItem.showDescription"
                        class="p-v-spacing item-description w-full wysiwyg border-t border-ace-gray-300"
                        [innerHTML]="accordionItem.itemDescription"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- End Single Bundle Mobile View -->
      <!--Single Bundle View-->
      <div *ngIf="bundles.length === 1"
        id="bundle"
        [className]="
          'hidden auto-cols-min justify-items-center gap-x-6 md:grid grid-cols-1 justify-items-center md:grid-cols-' +
          bundles.length +
          ' desktop-grid'
        "
      >
        <ng-template ngFor let-b [ngForOf]="bundles" let-last="last" let-index="index">
          <div
            class="pt-v-spacing w-full flex flex-col"
            [ngClass]="{ 'basic-column': index === 0, 'deluxe-column': index === 1, 'premium-column': index === 2 }"
          >
            <div
              [className]="getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root + ' rounded-t-md w-full'"
              [ngClass]="{ 'h-9': !!b.topContent, 'h-0 lg:h-9': !b.topContent }"
            >
              <span
                [className]="
                  getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root +
                  ' text-sm font-extrabold rounded-t-md inline-block uppercase py-2 px-6 w-full'
                "
                >{{ b.topContent }}</span
              >
            </div>
            <div
              class="w-full flex flex-col h-full shadow-xl bg-white text-vis-regular"
              [ngClass]="{ 'rounded-t-md': !b.topContent }"
            >
            <div class="inline-flex justify-start">
              <div class="flex-grow flex-shrink basis-0 flex-col justify-start items-start inline-flex">
                <div class="wysiwyg p-v-spacing" [innerHTML]="b.principalContent"></div>
                <div class="flex flex-col h-full justify-end">
                  <div class="w-full pt-0 pb-4 p-v-spacing">
                    <div [class]="b.savePercentage ? '' : 'invisible'" class="text-xs font-bold text-ace-pink-600">
                      SAVE <span *ngIf="b.discountType === 'PERCENT'">{{ b.savePercentage }}%</span><span *ngIf="b.discountType === 'FLAT'">${{ b.maxPrice - b.originalPrice }}</span>
                    </div>
                    <div class="flex">
                      <span
                        *ngIf="b.maxPrice && b.savePercentage"
                        class="text-lg font-extrabold price-cross-color line-through decoration-ace-grey-900 text-vis-regular mr-1"
                        >${{ b.maxPrice | customNumber : '1.0-0' }}</span
                      >
                      <span #bundlePrice *ngIf="b.originalPrice" class="text-lg font-extrabold text-ace-teal-900">
                        ${{ b.originalPrice | customNumber : '1.0-0' }}</span
                      >
                      <span
                        *ngIf="couponApplied"
                        class="coupon-applied text-[13px] font-bold text-ace-pink-600 ml-3 rounded-[30px] mb-[30px] py-[7px] px-[15px] bg-[#d9e7f2]"
                        >Coupon Applied!</span
                      >
                    </div>
                  </div>
                  <div *ngIf="b.originalPrice >= 50" class="pb-4 text-xs text-ace-grey-800 px-v-spacing">
                    <span
                      >As low as ${{ b.affirmPrice }}/mo with
                      <img class="inline-block affirm-img" src="https://xgrit-ecom.imgix.net/ace/affirm-logo.svg" />
                      †, or<br />
                      4 interest-free payments of ${{ b.klarnaPrice }} with
                      <img class="inline-block klarna-img" src="https://xgrit-ecom.imgix.net/ace/klarna-logo.svg" />
                      ††
                    </span>
                  </div>
                  
                  <div class="p-v-spacing w-full mt-4" *ngIf="b.bottomContent" [innerHTML]="b.bottomContent"></div>
                </div>

              </div>
              <div class="self-center justify-self-end pb-8">
                <div class="w-full pt-0 p-v-spacing items-center">
                  <div class="w-full xs:mr-v-spacing lg:mr-0 xl:mr-v-spacing">
                    <vision-button [data]="getButtonData(b)" [className]="'basic-cta_desktop'"></vision-button>
                  </div>
                  <div class="w-full mt-2 text-center text-sm cursor-pointer text-vis-subtle" (click)="toggleModal()">
                    <span *ngIf="b.product[0]" class="inline-block"
                      >{{guaranteeText}}</span
                    >
                  </div>
                </div>

              </div>

            </div>
              
            </div>
          </div>
        </ng-template>
      </div>
      <!--Single Bundle Accordion-->
      <div *ngIf="bundles.length === 1"
        [className]="
          'hidden md:grid grid-cols-1 justify-items-center md:grid-cols-' +
          accordions[0].length +
          ' accordion-desktop-grid shadow-xl rounded-b-md'
        "
      >
        <ng-template ngFor let-accordionItem [ngForOf]="accordions[0]" let-last="last" let-index="index">
          <div class="pt-0 w-full flex flex-col bg-white" [ngClass]="{'border-r': index < accordions[0].length - 1, 'rounded-bl-md': index === 0, 'rounded-br-md': index === accordions[0].length - 1}">
            <div
              class="desktop-accordion w-full bg-white text-vis-regular border-ace-gray-300 border-t rounded-b-md -mt-[1px]"
            >
              <div *ngIf="showAccordion(0)">
                  <div class="wrapper">
                    <div class="w-full border-ace-gray-300">
                      <div
                        class="group p-v-spacing item-title flex justify-between items-center border-b"
                      >
                        <div class="title w-full flex flex-row items-center">
                          <img
                            *ngIf="accordionItem.itemIcon && accordionItem.itemIcon[0] && accordionItem.itemIcon[0].url"
                            class="inline-block mr-4"
                            [src]="accordionItem.itemIcon[0].url"
                            width="40"
                            height="40"
                          />
                          <div class="inline-block align-top">
                            <p class="font-extrabold">{{ accordionItem.itemTitle }}</p>
                            <p class="block mt-0 font-normal" *ngIf="accordionItem.itemValueDescriptor">
                              {{ accordionItem.itemValueDescriptor }}
                            </p>
                          </div>
                          <span
                            *ngIf="accordionItem.itemBadge"
                            class="badge text-center text-sm font-extrabold rounded-[100px] bg-ace-teal-200 text-ace-teal-700 leading-[.8] py-2 px-3 ml-2.5 mr-3 border-ace-teal-700 border border-solid"
                            >{{ accordionItem.itemBadge }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="p-v-spacing item-description w-full wysiwyg border-ace-gray-300"
                        [innerHTML]="accordionItem.itemDescription"
                      ></div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- End Single Bundle View -->
      <!-- Desktop View -->
      <div *ngIf="bundles.length > 1"
        id="bundle"
        [className]="
          'hidden auto-cols-min justify-items-center gap-x-6 lg:grid grid-cols-1 justify-items-center lg:grid-cols-' +
          bundles.length +
          ' desktop-grid'
        "
      >
        <ng-template ngFor let-b [ngForOf]="bundles" let-last="last" let-index="index">
          <div
            class="pt-v-spacing w-full flex flex-col max-w-[366px]"
            [ngClass]="{ 'basic-column': index === 0, 'deluxe-column': index === 1, 'premium-column': index === 2 }"
          >
            <div
              [className]="getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root + ' rounded-t-md w-full'"
              [ngClass]="{ 'h-9': !!b.topContent, 'h-0 lg:h-9': !b.topContent }"
            >
              <span
                [className]="
                  getThemeForTopBanner(b.topBackgroundColor, b.topTextColor).root +
                  ' text-sm font-extrabold rounded-t-md inline-block uppercase py-2 px-6 w-full'
                "
                >{{ b.topContent }}</span
              >
            </div>
            <div
              class="w-full flex flex-col h-full shadow-xl bg-white text-vis-regular"
              [ngClass]="{ 'rounded-t-md': !b.topContent }"
            >
              <div class="wysiwyg p-v-spacing" [innerHTML]="b.principalContent"></div>
              <div class="flex flex-col h-full justify-end">
                <div class="w-full pt-0 pb-4 p-v-spacing">
                  <div [class]="b.savePercentage ? '' : 'invisible'" class="text-xs font-bold text-ace-pink-600">
                    SAVE <span *ngIf="b.discountType === 'PERCENT'">{{ b.savePercentage }}%</span><span *ngIf="b.discountType === 'FLAT'">${{ b.maxPrice - b.originalPrice }}</span>
                  </div>
                  <div class="flex">
                    <span
                      *ngIf="b.maxPrice && b.savePercentage"
                      class="text-lg font-extrabold price-cross-color line-through decoration-ace-grey-900 text-vis-regular mr-1"
                      >${{ b.maxPrice | customNumber : '1.0-0' }}</span
                    >
                    <span #bundlePrice *ngIf="b.originalPrice" class="text-lg font-extrabold text-ace-teal-900">
                      ${{ b.originalPrice | customNumber : '1.0-0' }}</span
                    >
                    <span
                      *ngIf="couponApplied"
                      class="coupon-applied text-[13px] font-bold text-ace-pink-600 ml-3 rounded-[30px] mb-[30px] py-[7px] px-[15px] bg-[#d9e7f2]"
                      >Coupon Applied!</span
                    >
                  </div>
                </div>
                <div *ngIf="b.originalPrice >= 50" class="pb-4 text-xs text-ace-grey-800 px-v-spacing">
                  <span
                    >As low as ${{ b.affirmPrice }}/mo with
                    <img class="inline-block affirm-img" src="https://xgrit-ecom.imgix.net/ace/affirm-logo.svg" />
                    †, or<br />
                    4 interest-free payments of ${{ b.klarnaPrice }} with
                    <img class="inline-block klarna-img" src="https://xgrit-ecom.imgix.net/ace/klarna-logo.svg" />
                    ††
                  </span>
                </div>
                <div class="w-full pt-0 p-v-spacing items-center">
                  <div class="w-full xs:mr-v-spacing lg:mr-0 xl:mr-v-spacing">
                    <vision-button [data]="getButtonData(b)" [className]="'basic-cta_desktop'"></vision-button>
                  </div>
                  <div class="w-full mt-2 text-center text-sm cursor-pointer text-vis-subtle" (click)="toggleModal()">
                    <span *ngIf="b.product[0]" class="inline-block"
                      >{{guaranteeText}}</span
                    >
                  </div>
                </div>
                <div class="p-v-spacing w-full mt-4" *ngIf="b.bottomContent" [innerHTML]="b.bottomContent"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="bundles.length > 1"
        [className]="
          'hidden gap-x-6 lg:grid grid-cols-1 justify-items-center lg:grid-cols-' +
          bundles.length +
          ' accordion-desktop-grid'
        "
      >
        <ng-template ngFor let-b [ngForOf]="bundles" let-last="last" let-index="index">
          <div class="pt-0 w-full flex flex-col max-w-[366px]">
            <div
              class="desktop-accordion w-full shadow-xl bg-white text-vis-regular border-ace-gray-300 border-t rounded-b-md -mt-[1px]"
            >
              <div *ngIf="showAccordion(index)">
                <div *ngFor="let accordionItem of accordions[index]">
                  <div class="wrapper">
                    <div class="w-full border-b border-ace-gray-300">
                      <div
                        class="group p-v-spacing item-title flex justify-between items-center cursor-pointer"
                        (click)="toggle(accordionItem)"
                      >
                        <div class="title w-full flex flex-row items-center">
                          <img
                            *ngIf="accordionItem.itemIcon && accordionItem.itemIcon[0] && accordionItem.itemIcon[0].url"
                            class="inline-block mr-4"
                            [src]="accordionItem.itemIcon[0].url"
                            width="40"
                            height="40"
                          />
                          <div class="inline-block align-top">
                            <p class="font-extrabold">{{ accordionItem.itemTitle }}</p>
                            <p class="block mt-0 font-normal" *ngIf="accordionItem.itemValueDescriptor">
                              {{ accordionItem.itemValueDescriptor }}
                            </p>
                          </div>
                          <span
                            *ngIf="accordionItem.itemBadge"
                            class="badge text-center text-sm font-extrabold rounded-[100px] bg-ace-teal-200 text-ace-teal-700 leading-[.8] py-2 px-3 ml-2.5 mr-3 border-ace-teal-700 border border-solid"
                            >{{ accordionItem.itemBadge }}</span
                          >
                        </div>
                        <div class="flex items-center bundle-fa">
                          <div class="icon text-ace-blue-600 group-hover:text-ace-blue-500 text-base text-lg mt-1">
                            <div *ngIf="!accordionItem.showDescription">
                              <span [innerHTML]="openIcon"></span>
                            </div>
                            <div *ngIf="accordionItem.showDescription">
                              <span [innerHtml]="closeIcon"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="accordionItem.showDescription"
                        class="p-v-spacing item-description w-full wysiwyg border-t border-ace-gray-300"
                        [innerHTML]="accordionItem.itemDescription"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- End Desktop View -->
    </div>
    <div class="bnpl-disclaimer mt-8">
      <div class="flex">
        <div class="bullet">†</div>
        <div>
          <p>
            Payment options are offered by Affirm and are subject to an eligibility check. Options depend on your
            purchase amount, and a down payment may be required. CA residents: Loans by Affirm Loan Services, LLC are
            made or arranged pursuant to a California Finance Lender license. For licenses and disclosures, see
            <a target="_blank" href="https://www.affirm.com/licenses">affirm.com/licenses</a>.
          </p>
        </div>
      </div>
      <div class="flex">
        <div class="bullet">††</div>
        <div>
          <p>
            See Klarna payment
            <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/sliceitinx" target="_blank">terms</a
            >. A higher initial payment may be required for some consumers. CA resident loans made or arranged pursuant
            to a California Financing Law license.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showModal" class="fixed inset-0 bg-vis-dark/50 z-50 flex justify-center items-center">
  <div class="w-3/4 lg:w-1/4 bg-white text-vis-regular shadow-md py-v-spacing px-v-spacing relative rounded">
    <div class="font-bold mb-v-spacing flex justify-between cursor-pointer">
      <div class="mr-1">{{guaranteeModalTitle}}</div>
      <fa-icon (click)="toggleModal()" [icon]="faTimes"></fa-icon>
    </div>
    <div>
      {{guaranteeModalBody}}
    </div>
  </div>
</div>
