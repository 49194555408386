import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { EnvService } from '@core-mkt/services/env/env.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faStar as farStar, faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { ProductItem } from '../product-card';

@Component({
  selector: 'card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent implements OnInit {
  @Input() data: ProductItem;
  @Input() ctaType: string;
  @Input() hideCta: boolean;
  @Input() learnMore: boolean;
  theme: ThemeInterface;
  product: XGritCompleteProduct;
  fasStar = fasStar;
  farStar = farStar;
  faStarStyles = { width: '13px', color: '#fcb11a' };
  priceText = '';
  altPriceText = '';

  constructor(private themeParser: ThemeParserService, private env: EnvService) {}

  get ctaLabel(): string {
    return this.data.ctaLabel ? this.data.ctaLabel : this.env.get.brandConfig.ctaText;
  }

  get checkStyle(): string {
    return this.env.get.brandConfig.checkClassName;
  }

  get ctaLink(): string | undefined {
    if (this.data.ctaLink) {
      return this.data.ctaLink;
    } else {
      if (this.product) {
        return this.product.apiData.checkoutLink;
      } else {
        return undefined;
      }
    }
  }

  get ctaLinkAriaLabel(): string | undefined {
    if (this.data.ctaLink) {
      return undefined;
    } else {
      if (this.product) {
        return this.product.apiData.title;
      } else {
        return undefined;
      }
    }
  }

  get isLearnMoreIncluded(): boolean {
    return this.learnMore;
  }

  get showCTA(): boolean {
    return this.ctaLabel && this.ctaLink && !this.hideCta;
  }

  get disclaimer(): string {
    return this.product && this.product.craftData.disclaimer;
  }

  get showDisclaimer(): boolean {
    const disclaimer = this.product && this.product.craftData.disclaimer;
    return (
      this.product && this.product.craftData && disclaimer !== null && disclaimer !== undefined && disclaimer.length > 0
    );
  }

  get ctaNewTab(): boolean {
    return this.data.ctaNewTab;
  }

  get ctaNofollow(): boolean {
    return this.data.ctaNofollow;
  }

  get lmLabel(): string {
    return this.data.learnMoreLabel !== null && this.data.learnMoreLabel.length > 0
      ? this.data.learnMoreLabel
      : 'Learn More';
  }

  get lmLink(): string | undefined {
    return this.data.learnMoreLink !== null && this.data.learnMoreLink.length > 0 ? this.data.learnMoreLink : undefined;
  }

  get lmNewTab(): boolean {
    return this.data.learnMoreNewTab;
  }

  get lmNofollow(): boolean {
    return this.data.learnMoreNofollow;
  }

  get salePrice(): string {
    return '';
  }

  get savings(): string {
    return this.product ? this.calculateSavings() : '0';
  }

  get showSavings(): boolean {
    return parseInt(this.savings) !== 0 && this.data.showSavings;
  }

  get showPricing(): boolean {
    return this.product && this.data.showPricing && !this.hideCta;
  }

  get showOriginal(): boolean {
    return (
      this.product && this.product.apiData && this.product.apiData.discountPrice < this.product.apiData.originalPrice
    );
  }

  get starRating(): number | undefined {
    return this.data.starRating ? parseInt(this.data.starRating, 10) : undefined;
  }

  get starCaption(): string {
    return this.data.starCaption;
  }

  get textContent(): string {
    return this.data.textContent;
  }

  get cardStyles(): string {
    if (this.data.cardBackgroundColor) {
      return this.data.cardBackgroundColor;
    }
    if (this.data.styles) {
      return this.data.styles.join(' ');
    }
    return 'bg-white text-vis-regular';
  }

  get buttonData(): VisionButton {
    return {
      bgTheme: this.cardStyles,
      buttonLink: this.ctaLink,
      buttonAriaLabel: this.ctaLinkAriaLabel,
      buttonText: this.ctaLabel,
      buttonStyle: this.ctaType,
      useIcon: false,
      ctaTag: this.data.productCtaTag,
      newTab: this.data.ctaNewTab,
      nofollow: this.data.ctaNofollow,
      noopener: this.data.ctaNewTab,
      noreferrer: this.data.ctaNewTab,
      fullWidth: true,
    };
  }

  get buttonLinkData(): VisionButton {
    return {
      bgTheme: this.cardStyles,
      buttonLink: this.lmLink,
      buttonAriaLabel: this.product ? this.product.apiData.title : undefined,
      buttonText: this.lmLabel,
      buttonStyle: 'tertiary',
      useIcon: false,
      newTab: this.data.learnMoreNewTab,
      nofollow: this.data.learnMoreNofollow,
      noopener: this.data.learnMoreNewTab,
      noreferrer: this.data.learnMoreNewTab,
      fullWidth: false,
      buttonAlignment: 'center',
    };
  }

  ngOnInit(): void {
    this.priceText = this.env.get.brandConfig.pricingText;
    if (this.env.get.brandConfig.altPricingText) {
      this.altPriceText = this.env.get.brandConfig.altPricingText;
    }
    this.product = this.data.xGritProduct;
    if (!this.showOriginal && this.altPriceText.length > 0) {
      this.priceText = this.altPriceText;
    }
    const themeInput: ComponentTheme = {
      styles: [this.cardStyles],
      backgroundColor: this.cardStyles,
      backgroundImage: undefined,
      textColor: this.data.cardTextColor,
      columnCount: 0,
      columnDistribution: undefined,
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }

  calculateSavings(): string {
    const difference = this.product.apiData.originalPrice - this.product.apiData.discountPrice;
    if (difference >= 0) {
      if (Number.isInteger(difference)) {
        return '' + difference;
      } else {
        return difference.toFixed(2);
      }
    }
    return '';
  }
}
