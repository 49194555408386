/* eslint-disable prettier/prettier */
import { ProductFragment } from '@core-mkt/shared/craft/product-entry-fragment';
import * as gql from 'fake-tag';

export const TopSectionFragment = {
  TopSection: gql`
    fragment TopSectionQuery on baseComponents_topSectionWysiwyg_BlockType {
      backgroundImage {
        url
      }
      bottomRightContent {
        ... on bottomRightContent_BlockType {
          sealIcon {
            url
            ... on images_Asset {
              alt
            }
          }
          sealIconText
        }
      }
      categoryWidgets {
        ... on categoryWidgets_Category {
          categoryContent
          categoryImage {
            url
            ... on images_Asset {
              altText
            }
          }
          categoryTitle
        }
      }
      contentAlignment
      ctaLabel
      ctaNewTab
      ctaNofollow
      ctaType
      ctaLink
      ctaTag
      eyebrow
      featuredContent {
        ... on featuredContent_BlockType {
          featuredContentIcon {
            url
            ... on images_Asset {
              altText
            }
          }
          featuredContentText
        }
      }
      hideGuaranteeLink
      highlightedTextModal1 {
        ... on highlightedTextModal1_BlockType {
          modalContent
        }
      }
      highlightedTextModal2 {
        ... on highlightedTextModal2_BlockType {
          modalContent
        }
      }
      lineBreak_buttons
      lineBreak_mainContent
      lineBreak_modals
      lineBreak_rightContent
      lineBreak_styles
      modalContent
      courseOverviewPopup
      product {
        ...ProductEntry
      }
      productSeal {
        url
        ... on images_Asset {
          altText
        }
      }
      rightContent
      sectionContent
      sectionTitle
      showOfferTimer
      showPricing
      showTrustpilotStars
      starCaption
      starRating
      styles
      typeHandle
      videoButton {
        ... on videoButton_BlockType {
          ctaLabel
          ctaType
          wistiaId
        }
      }
      widgetTip
    }
    ${ProductFragment.product}
  `,
};
