import { DOCUMENT } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, ElementRef, HostListener, Inject, Input, ViewChild } from '@angular/core';
import { Brand } from '@core-mkt/enums/brand.enum';
import { CountdownService } from '@core-mkt/services/countdown/countdown.service';
import { ElementVisibilityService } from '@core-mkt/services/element-visibility/element-visibility.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WistiaService } from '@core-mkt/services/wistia/wistia.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { faAngleDown, faAngleUp, faStar, faStar as faStarRegular, faTimes } from '@fortawesome/free-solid-svg-icons';
import type { Observable } from 'rxjs';
import { TopProduct } from './top-product';

@Component({
  selector: 'top-product',
  templateUrl: './top-product.component.html',
  styleUrls: ['./top-product.scss'],
})
export class TopProductComponent implements OnInit, AfterViewInit {
  @Input() data: TopProduct;
  @ViewChild('topProductCta', { static: false }) topProductCta: ElementRef;
  @ViewChild('topProductImage') topProductImage: ElementRef;
  faTimes = faTimes;
  emptyStar = faStarRegular;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  hasContent = false;
  modalHasContent = false;
  showTitle = false;
  showContent = false;
  showModal = false;
  starCount = -1;
  starList: number[] = [];
  star = faStar;
  product: XGritCompleteProduct;
  theme: ThemeInterface;
  originalPrice: number;
  discountPrice: number;
  countdown: Observable<number>;
  offerEndTime: number = null;
  productCtaLink: string;
  productCtaLinkAriaLabel: string;
  pricingText: string;
  wistiaEmbed: string = null;
  private readonly brandId: string = new EnvService().get.xgritApiConfig.baseParams.brandId;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private ps: ProductService,
    private countdownService: CountdownService,
    private elementVisibility: ElementVisibilityService,
    private env: EnvService,
    private wistia: WistiaService,
    public imgixService: ImgixService,
    private pricing: PricingService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /**
   * Returns the label for the money guarantee label based on the brand ID.
   * @returns The label for the money guarantee.
   */
  get moneyGuaranteeLabel(): string {
    return this.brandId === Brand.AARP ? 'Money-Back Guarantee!**' : '100% Money Back Guarantee';
  }

  /**
   * Returns the label for the money guarantee label based on the brand ID.
   * @returns The label for the money guarantee.
   */
  get moneyGuaranteeLinkStyle(): string {
    // Note: This is a temporary text style for the AARP brand link, color could be renamed in the future
    return this.brandId === Brand.AARP
      ? 'text-ace-teal-700 border-b-2 border-b-ace-teal-700'
      : 'text-ace-teal-700 border-b-2 border-b-ace-teal-700';
  }

  get ctaDisplay(): boolean {
    return this.elementVisibility.isElVisible;
  }

  get checkStyle(): string {
    return this.env.get.brandConfig.checkClassName;
  }

  get imgAlign(): string {
    return this.data.imageAlignment ? 'lg:' + this.data.imageAlignment : 'lg:flex-row mx-auto';
  }

  get ctaType(): string {
    return this.data.ctaType === null
      ? 'bg-ace-blue-600 hover:bg-ace-blue-500 text-vis-reverse h-12 inline-flex items-center justify-center text-base md:text-md'
      : this.data.ctaType;
  }

  get displayPrice(): boolean {
    return this.data.displayPrice;
  }

  get displayOfferTimer(): boolean {
    return this.data.displayOfferTimer;
  }

  get ctaLabel(): string {
    return this.data.ctaLabel === null ? 'Start Today' : this.data.ctaLabel;
  }

  get customCtaLink(): string {
    return this.data.ctaLink;
  }

  get ctaNewTab(): boolean {
    return this.data.ctaNewTab;
  }

  get ctaNofollow(): boolean {
    return this.data.ctaNofollow;
  }

  get buttonScrollData(): VisionButton {
    return {
      buttonStyle: this.buttonScrollStyle,
      bgTheme: this.theme.root,
      buttonText: this.buttonScrollLabel,
      buttonLink: this.buttonScrollTo,
      fullWidth: false,
      nofollow: false,
      noreferrer: false,
      noopener: false,
      newTab: false,
    };
  }

  get buttonScrollLabel(): string {
    return this.data.buttonScrollLabel;
  }

  get buttonScrollStyle(): string {
    return this.data.buttonScrollStyle;
  }

  get buttonScrollTo(): string {
    return this.data.buttonScrollTo;
  }

  get buttonScrollArrowDirection(): string {
    return this.data.buttonScrollArrowDirection;
  }

  get topProductButtonData(): VisionButton {
    return {
      bgTheme: '',
      buttonLink: this.customCtaLink ? this.customCtaLink : this.productCtaLink,
      buttonText: this.ctaLabel,
      buttonAriaLabel: this.productCtaLinkAriaLabel,
      buttonStyle: this.ctaType,
      fullWidth: false,
      newTab: this.ctaNewTab,
      nofollow: this.ctaNofollow,
      noopener: this.ctaNewTab,
      noreferrer: this.ctaNewTab,
    };
  }

  ngOnInit(): void {
    this.document.addEventListener('keydown', (event: KeyboardEvent) => this.handleKeyDown(event));
    this.offerEndTime = this.getOfferEndTime();
    this.countdown = this.countdownService.create(this.offerEndTime);
    if (this.data.topProductTitle !== null) {
      this.hasContent = true;
      this.showTitle = true;
      this.data.topProductTitle = this.redactor.bypassSanitizer(this.data.topProductTitle);
    }
    if (this.data.topProductContent !== null) {
      this.hasContent = true;
      this.showContent = true;
      this.data.topProductContent = this.redactor.bypassSanitizer(this.data.topProductContent, 'lazy');
    }
    if (this.data.modalContent !== null) {
      this.hasContent = true;
      this.modalHasContent = true;
      this.data.modalContent = this.redactor.bypassSanitizer(this.data.modalContent);
    }
    /*** Top Bundle Only ***/
    if (this.data.topBundleTopRightContent) {
      this.data.topBundleTopRightContent = this.redactor.bypassSanitizer(this.data.topBundleTopRightContent, 'lazy');
    }
    if (this.data.topBundleBottomRightContent) {
      this.data.topBundleBottomRightContent = this.redactor.bypassSanitizer(
        this.data.topBundleBottomRightContent,
        'lazy',
      );
    }
    /*** Top Bundle Only ***/

    if (this.ps.isProductValid(this.data.product)) {
      this.data.product = this.ps.extractProduct(this.data.product);
      this.hasContent = true;
      this.ps.getProductData(this.data.product).then((product: XGritCompleteProduct) => {
        this.product = product;
        this.ps.setProduct(product);
        this.pricingText = this.pricing.getPricingText(this.product, this.data.pricingText);
        this.originalPrice = product.apiData.originalPrice;
        this.discountPrice = product.apiData.discountPrice;
        this.productCtaLink = product.apiData.checkoutLink;
        this.productCtaLinkAriaLabel = product.apiData.title;
      });
    }
    if (this.data.starRating && this.data.starRating.length > 0) {
      this.starCount = parseInt(this.data.starRating);
      if (this.starCount > 0) {
        this.starList = Array(this.starCount).fill(4);
      }
    }
    if (!this.hasContent) {
      this.hasContent =
        this.data.productSeal.length > 0 ||
        this.data.starRating.length > 0 ||
        (this.data.topProductImage &&
          this.data.topProductImage.length > 0 &&
          this.data.topProductImage[0].url !== null) ||
        this.data.ctaLabel.length > 0 ||
        this.data.ctaLink.length > 0;
    }
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);

    if (this.data.topProductWistiaVideoId) {
      this.wistiaEmbed = this.redactor.bypassSanitizer(
        this.wistia.wistiaSingleInputParser(this.data.topProductWistiaVideoId),
      );
    }
  }

  ngAfterViewInit(): void {
    this.onWindowScroll();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.topProductCta) {
      this.elementVisibility.checkElementVisiblity(this.topProductCta.nativeElement);
    }
  }

  private getOfferEndTime = (): number => {
    let endTime = null;
    // If we receive start and end dates for promotion, we need to calculate the range of time between them.
    if (
      this.data &&
      this.data.countdownInfo &&
      this.data.countdownInfo.promotionStartDateTime &&
      this.data.countdownInfo.promotionEndsDateTime
    ) {
      const date = new Date();
      const startDate = new Date(this.data.countdownInfo.promotionStartDateTime);
      const endDate = new Date(this.data.countdownInfo.promotionEndsDateTime);
      // If the actual date is greater than the promo start date and below the promo end date, then the timer will get the remaining time until the end date.
      if (date < endDate && date > startDate) {
        endTime = endDate.valueOf();
      }
    }
    return endTime;
  };

  activateModal(): void {
    if (this.modalHasContent) {
      this.showModal = true;
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Enter') {
      this.showModal = false;
    }
  }

  handleBlur(id: string): void {
    if (id === 'close-modal') {
      this.document.getElementById('modal-content').focus();
    }
  }

  getAlt(section: string): string {
    let altText = 'a CMS loaded image';
    if (section === 'prod') {
      altText = 'A product image';
    }
    if (section == 'seal') {
      altText = 'A product seal';
    }
    //this.data.topProductImage not in v3 on aug 15
    return this.data.topProductImage && this.data.topProductImage.length > 0 && this.data.topProductImage[0].altText
      ? this.data.topProductImage[0].altText
      : altText;
  }
}
