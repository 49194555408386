<a *ngIf="link.linkUrl !== '#'" class="font-body text-ace-grey-900 collapse-link font-bold flex align-middle justify-content-between p-6 border-b" [attr.href]="link.linkUrl">
  <span class="flex-grow">{{ link.linkLabel }}</span>
</a>
<a *ngIf="link.linkUrl === '#'" class="font-body text-ace-grey-900 collapse-link font-bold flex align-middle justify-content-between p-6 border-b" href="#" (click)="collapsed = !collapsed">
  <span class="flex-grow">{{ link.linkLabel }}</span>
  <fa-icon *ngIf="collapsed" class="text-ace-grey-900 fa-1x" [icon]="faCaretRight"></fa-icon>
</a>
<div class="link-container absolute top-0 bg-white z-50 overflow-y-auto w-full -right-full h-screen" [class.slide-in]="!collapsed">
  <div *ngIf="link.ddList && link.ddList.length > 0" class="text-base text-ace-grey-900 mb-4 collapse-link__item">
    <div class="back-button flex flex-row pl-8 border-t border-b border-ace-grey-400 bg-ace-grey-100 py-3 text-ace-grey-700 font-bold cursor-pointer" (click)="collapsed = !collapsed">
      <fa-icon class="fa-1x mr-2" [icon]="faArrowLeft"></fa-icon>
      <span class="block">Back</span>
    </div>
    <a class="py-5 block pl-8 border-b border-ace-grey-400 uppercase text-ace-grey-600 text-sm font-extrabold">{{ addCoursesSuffixIfNeeded(link.linkLabel) }}</a>
    <a *ngFor="let linkItem of link.ddList" class="py-5 block pl-8 border-b" [href]="linkItem.linkUrl" [innerHtml]="linkItem.linkLabel"></a>
  </div>
</div>
