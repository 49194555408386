<a
  class="{{styleClass}}-button v-button"
  [ngClass]="className"
  href="{{buttonLink}}"
  [attr.data-formelementid]="dataFormElementId ? dataFormElementId : null"
  [attr.target]="target"
  [rel]="rel"
  [attr.aria-label]="getAriaLabelLinkInVisionButton(buttonLink)"
>
  <span class="md:whitespace-nowrap"
  [class.tertiary-border]="!useIcon && buttonStyle ===  'tertiary'">
    {{text}}
    <fa-icon
      *ngIf="linkIcon && useIcon && buttonStyle==='tertiary'"
      class="arrow-right fa-1x pl-1"
      [icon]="linkIcon"
    >
    </fa-icon>
  </span>
</a>
